import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { deleteDate, getAllDates } from "../../../api/agenda";
import Success from "../../../component/alert/Success";
import Breadcrumb from "../../../component/Breadcrumb";
import { ConfirmModal } from "../../../component/ConfirmModal"
import { Table } from "../../../component/Table"
import { FaPlus } from "react-icons/fa";

const struct = [
    {key: "title", text: "Titre"},
    {key: "date", text: "Date"},
    {key: "description", text: "Description"},
]

export function Agenda() {
    const navigate = useNavigate();
    const [dates, setDates] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false)
    const [dateId, setDateId] = useState(-1); 
    const [success, setSuccess] = useState({show: false, message: ""});


    function loadDates() {
        getAllDates().then((data) => {
            setDates(data.data);
        })
    }

    useEffect (() => {
        loadDates();
    }, [])
    
    function performDelete(){
        if(dateId>0){
            deleteDate(dateId).then(() => {                
                loadDates();
                setSuccess({show: true, message: "Suppression effectuée!"});
            })
        }
        setShowConfirm(false);
    }

    function cutDescritption(description){
        //If string longer than 40 characters, cut it and add "..."
        if(description.length > 40){
            return description.substring(0, 40) + "...";
        }
        return description;
    }

    function getDataTable(){
        return dates.map((date) => {
            return {
                id: date.id,
                title: date.title,
                date: date.date,
                description: cutDescritption(date.description)
            }
        })
    }

    function confirmDelete(id){
        setDateId(id);
        setShowConfirm(true);
    }

    const actions = [
        {callback: (id) => {navigate("./"+id)}, text: "Modifier", color: "blue", key: "update"},
        {callback: confirmDelete, text: "Supprimer", color: "red", key: "delete"}
    ]

    return(
        <div>
            <Breadcrumb className="sm:pl-5" current="Agenda"/>
            <div className="flex items-end justify-between px-1 sm:px-10 py-5">
                <h2 className="text-2xl sm:text-4xl w-full sm:w-auto text-center font-bold dark:text-white">Agenda</h2> 
                <button type="button" onClick={() => {navigate("./0")}} className="hidden sm:flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Ajouter une date</button>   
            </div>
            <div className="m-1 sm:m-10 mt-5 sm:mt-10">
                <Table headers={struct} data={getDataTable(dates)} actions={actions}/>
            </div>
            <div onClick={() => {navigate("./0")}} className="sm:hidden h-[60px] w-[60px] bg-blue-500 dark:bg-blue-700 rounded-lg fixed lg:hidden bottom-4 right-4 flex">
                <FaPlus className="text-white h-[40px] w-[40px] m-auto"/>
            </div>
            <ConfirmModal isShowed={showConfirm} hide={() => {setShowConfirm(false)}} valid={performDelete} />
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}