import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getContact } from "../api/contacts";


const EMA = () => {
    const [contact, setContact] = useState({})

    function loadContact() {
        getContact(1).then((data) => {
            setContact(data.data);
        })
    }

    useEffect (() => {
        loadContact();
    }, []) 
    return <div className="max-w-[1000px] m-auto p-5 text-justify">        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Ecole de musique</h1>
            <small className="font-semibold text-gray-500 md:text-2xl text-lg md:ml-10 md:text-4xl">de l'Avenir de Saxon</small>
        </div>

        <div>
            <p className="mt-10 mb-10 text-base font-normal tracking-tight text-gray-900">
                L'Avenir de saxon investit dans le future en soutenant la formation de jeunes musiciens. Nous encourageons les jeunes à suivre leur formation au conservatoire. Grâce à leur bonne formation, les jeunes pourront rapidement rejoindre les rangs de la fanfare.
            </p>
            <p>
                Si vous êtes intéressé, n'hésitez pas à contacter notre responsable de l'école de musique.
            </p>
        </div>
        <div className="m-auto mt-10 grid grid-cols-1 gap-4 mb-10 max-w-[1000px]">
            <div href="#" className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row">
                <FontAwesomeIcon icon={faUserTie} className="md:w-[150px] w-[100px] h-[100px] md:h-[150px] m-4" style={{color: "#44464b",}} />
                <div className="p-4 pt-0 h-full">
                    <h5 className="my-2 md:text-xl text-base font-bold tracking-tight text-gray-900 text-center">Responsable de l'école de musique</h5>
                    <div>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faAddressBook} /></span>{contact.responsableEMAName}</p>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faPhone} /></span>{contact.responsableEMAPhone}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};
  
export default EMA;